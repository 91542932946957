body.modal-open {
    overflow: auto;
}

.dark-theme .ant-modal-content{
    background-color: #1E1E2D !important;
}

.light-theme .ant-modal-content {
    background-color: #ffffff !important;
}

.dark-theme .ant-modal-header{
    background-color: #1E1E2D !important;
}

.light-theme .ant-modal-header{
    background-color: #FFFFFF !important;
}

.dark-theme .ant-modal-title{
    color: white !important;
}

.light-theme .ant-modal-title{
    color: black !important;
}

.dark-theme .ant-modal-close-x {
    color: white !important;
}

.light-theme .ant-modal-close-x {
    color: black !important;
}

.light-theme-text {
    color: black
}

.bigger-icon {
    font-size: 24px;
    cursor: pointer;
}

.dark-editor-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    background-color: #3a3a3f;
    padding-top: 5px;
    padding-bottom: 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.light-editor-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    background-color: #F8F8F8;
    padding-top: 5px;
    padding-bottom: 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.dark-theme .ant-pagination-item-active {
    color: white;
}

.light-theme .ant-pagination-item-active {
    color: black;
}

.dark-theme .ant-select-dropdown {
    background-color: #151521;
}

.dark-theme .ant-select-item-option-content {
    color: #0095E8;
}



