.dark-theme .ant-pagination-item-active {
    background-color: #151521;
    color: white;
}

.light-theme .ant-pagination-item-active {
    background-color: #F8F8F8;
    color: black;
}

.dark-theme .anticon-right {
    color: white;
}

.dark-theme .anticon-left {
    color: white;
}

.dark-theme .ant-steps-item-title {
    color: white
}

.light-theme .ant-steps-item-title {
    color: black
}

.queue-info-tab-button {
    padding: 5px 10px;
    cursor: pointer;
    border: 1px solid;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: bolder;
}

.queue-info-tab-active-button {
    color: white;
    background-color: #0095E8;
}

.queue-info-tab-disabled-button {
    padding: 5px 10px;
    border: 1px solid;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: bolder;
}

.info-text {
    position: absolute;
    background-color: #f9f9f9;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1;
}

.info-text-dark {
    position: absolute;
    background-color: #1E1E2D;
    color: white;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1;
}