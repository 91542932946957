.dark-theme .anticon-close-circle{
    color: white
}

.light-theme .anticon-close-circle{
    color: black
}

.dark-theme .anticon{
    color: white
}

.light-theme .anticon{
    color: darkgray
}