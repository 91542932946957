.groups-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1px;
}

.group-link-item {
    margin-bottom: 8px;
    margin-top: 8px;
    font-size: 15px;
    font-family: Inter, Helvetica, 'sans-serif';
}

.group-link-item:hover {
    color: #1B84FF;
}

.group-link-active-item {
    color: #1B84FF;
    margin-bottom: 8px;
    margin-top: 8px;
    font-size: 14px;
    font-family: Inter, Helvetica, 'sans-serif';
    border: 1px solid #1B84FF;
    padding-right: 8px;
    padding-left: 8px;
    border-radius: 10px;
}

.group-count-item {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1B84FF;
    background-color: #E9F3FF;
    padding: 5px;
    width: 25px;
    height: 25px;
    border-radius: 10px;
    font-size: 14px;
    font-family: Inter, Helvetica, 'sans-serif';
}