.light-theme .ant-empty-image{
    color: black !important;
}

.dark-theme .ant-empty-image{
    color: white !important;
}

.bigger-icon {
    font-size: 24px;
    cursor: pointer;
}

.dark-theme .ant-pagination-item {
    color: white
}

.dark-theme .ant-pagination-item-active {
    background-color: #151521;
    color: black;
}

.light-theme .ant-pagination-item-active {
    background-color: #F8F8F8;
    color: black;
}

.dark-theme .anticon-right {
    color: white;
}

.dark-theme .anticon-left {
    color: black;
}

.dark-theme .ant-select-dropdown {
    background-color: #151521;
}

.dark-theme .ant-select-item-option-content {
    color: #0095E8
}

.draft-list-container{
    max-height: 300px;
    /* Her öğe 60px yüksekliğinde ve sayfa başına 5 öğe gösteriliyor */
    overflow-y: auto;
    /* Dikey kaydırma eklenir */
}